// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    component: () => import('../views/Home.vue'),
    meta: { title: 'صفحه اصلی - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/services', 
    name: 'Services', 
    component: () => import('../views/Services.vue'),
    meta: { title: 'خدمات - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/person-tax', 
    name: 'PersonTax', 
    component: () => import('../views/PersonTax.vue'),
    meta: { title: 'محاسبه مالیات اشخاص - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/csr-maker', 
    name: 'CSRMaker', 
    component: () => import('../views/CSRMaker.vue'),
    meta: { title: 'تولید کلید CSR - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/depreciation', 
    name: 'Depreciation', 
    component: () => import('../views/Depreciation.vue'),
    meta: { title: 'محاسبه استهلاک - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/salary', 
    name: 'Salary', 
    component: () => import('../views/Salary.vue'),
    meta: { title: 'محاسبه حقوق و دستمزد - بهین سیستم ماندگار' }
  },
  { 
    path: '/online-services/stuff-id-search', 
    name: 'StuffIdSearch', 
    component: () => import('../views/StuffIdSearch.vue'),
    meta: { title: 'جستجوی شناسه کالا/خدمات - بهین سیستم ماندگار' }
  },
  { 
    path: '/support/request', 
    name: 'SupportRequest', 
    component: () => import('../views/SupportRequest.vue'),
    meta: { title: 'درخواست پشتیبانی - بهین سیستم ماندگار' }
  },
  { 
    path: '/support/history', 
    name: 'SupportHistory', 
    component: () => import('../views/SupportHistory.vue'),
    meta: { title: 'تاریخچه پشتیبانی - بهین سیستم ماندگار' }
  },
  { 
    path: '/support/faq', 
    name: 'FAQ', 
    component: () => import('../views/FAQ.vue'),
    meta: { title: 'سوالات متداول - بهین سیستم ماندگار' }
  },
  { 
    path: '/info/behin-news', 
    name: 'BehinNews', 
    component: () => import('../views/BehinNews.vue'),
    meta: { title: 'اخبار بهین سیستم - بهین سیستم ماندگار' }
  },
  { 
    path: '/info/tax-news', 
    name: 'TaxNews', 
    component: () => import('../views/TaxNews.vue'),
    meta: { title: 'اخبار مالیاتی - بهین سیستم ماندگار' }
  },
  { 
    path: '/info/tax-announcements', 
    name: 'TaxAnnouncements', 
    component: () => import('../views/TaxAnnouncements.vue'),
    meta: { title: 'اطلاعیه‌های مالیاتی - بهین سیستم ماندگار' }
  },
  { 
    path: '/contracts/my-contracts', 
    name: 'MyContracts', 
    component: () => import('../views/MyContracts.vue'),
    meta: { title: 'قراردادهای من - بهین سیستم ماندگار' }
  },
  { 
    path: '/contracts/new-contract', 
    name: 'NewContract', 
    component: () => import('../views/NewContract.vue'),
    meta: { title: 'قرارداد جدید - بهین سیستم ماندگار' }
  },
  { 
    path: '/base-info', 
    name: 'BaseInfo', 
    component: () => import('../views/BaseInfo.vue'),
    meta: { title: 'اطلاعات پایه - بهین سیستم ماندگار' }
  },
  { 
    path: '/base-info/fiscal-year', 
    name: 'FiscalYear', 
    component: () => import('../views/FiscalYear.vue'),
    meta: { title: 'سال مالی - بهین سیستم ماندگار' }
  },
  { 
    path: '/base-info/people', 
    name: 'People', 
    component: () => import('../views/People.vue'),
    meta: { title: 'اشخاص - بهین سیستم ماندگار' }
  },
  { 
    path: '/base-info/coding', 
    name: 'Coding', 
    component: () => import('../views/Coding.vue'),
    meta: { title: 'کدینگ - بهین سیستم ماندگار' }
  },
  { 
    path: '/base-info/detailed-coding', 
    name: 'DetailedCoding', 
    component: () => import('../views/DetailedCoding.vue'),
    meta: { title: 'کدینگ تفصیلی - بهین سیستم ماندگار' }
  },
  { 
    path: '/documents', 
    name: 'Documents', 
    component: () => import('../views/Documents.vue'),
    meta: { title: 'اسناد - بهین سیستم ماندگار' }
  },
  { 
    path: '/documents/financial', 
    name: 'FinancialDocuments', 
    component: () => import('../views/FinancialDocuments.vue'),
    meta: { title: 'اسناد مالی - بهین سیستم ماندگار' }
  },
  { 
    path: '/documents/reports/general', 
    name: 'GeneralReport', 
    component: () => import('../views/GeneralReport.vue'),
    meta: { title: 'گزارش عمومی - بهین سیستم ماندگار' }
  },
  { 
    path: '/documents/reports/profit-loss', 
    name: 'ProfitLossReport', 
    component: () => import('../views/ProfitLossReport.vue'),
    meta: { title: 'گزارش سود و زیان - بهین سیستم ماندگار' }
  },
  { 
    path: '/about', 
    name: 'About', 
    component: () => import('../views/About.vue'),
    meta: { title: 'درباره ما - بهین سیستم ماندگار' }
  },
  { 
    path: '/partners', 
    name: 'Partners', 
    component: () => import('../views/Partners.vue'),
    meta: { title: 'همکاران ما - بهین سیستم ماندگار' }
  },
  { 
    path: '/contact', 
    name: 'Contact', 
    component: () => import('../views/Contact.vue'),
    meta: { title: 'تماس با ما - بهین سیستم ماندگار' }
  },
  { 
    path: '/learning', 
    name: 'Learning', 
    component: () => import('../views/Learning.vue'),
    meta: { title: 'آموزش ها - بهین سیستم ماندگار' }
  },
  { 
    path: '/learning/free-learning', 
    name: 'FreeLearning', 
    component: () => import('../views/FreeLearnings.vue'),
    meta: { title: 'آموزش های رایگان - بهین سیستم ماندگار' }
  },
  { 
    path: '/:param(.*)', 
    name: '404', 
    component: () => import('../views/404.vue'),
    meta: { title: 'صفحه پیدا نشد - بهین سیستم ماندگار' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// تنظیم عنوان پویا
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'بهین سیستم ماندگار';
  next();
});

export default router;