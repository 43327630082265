<template>
  <div class="sidebar" :class="{ 'collapsed': isCollapsed }">
    <!-- هدر -->
    <div class="header">
      <img src="../../assets/logo.png" alt="بهین سیستم ماندگار" class="logo" />
      <span class="brand-name" v-show="!isCollapsed">بهین سیستم ماندگار</span>
    </div>
    <button class="toggle-btn" @click="toggleMenu">
      <i :class="isCollapsed ? 'fas fa-bars' : 'fas fa-times'"></i>
    </button>

    <!-- منو -->
    <nav class="menu">
      <ul>
        <li v-for="item in menuItems" :key="item.id">
          <router-link
            :to="item.route"
            @click="item.submenu ? toggleSubmenu(item.id) : handleClick(item)"
            :class="{ 'has-submenu': item.submenu, 'active': isActive(item.route?.name) }"
          >
            <span class="icon"><i :class="item.iconClass"></i></span>
            <span class="title" v-show="!isCollapsed">{{ item.title }}</span>
            <span class="arrow" v-if="item.submenu && !isCollapsed">
              <i :class="activeSubmenus.includes(item.id) ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </span>
          </router-link>

          <ul
            v-if="item.submenu"
            class="submenu"
            :class="{ 'active': activeSubmenus.includes(item.id) }"
            v-show="!isCollapsed"
          >
            <li v-for="subItem in item.submenu" :key="subItem.id">
              <router-link
                :to="subItem.route"
                @click="subItem.submenu ? toggleSubmenu(subItem.id) : handleClick(subItem)"
                :class="{ 'has-submenu': subItem.submenu, 'active': isActive(subItem.route?.name) }"
              >
                <span class="icon"><i :class="subItem.iconClass"></i></span>
                <span class="title">{{ subItem.title }}</span>
                <span class="arrow" v-if="subItem.submenu">
                  <i :class="activeSubmenus.includes(subItem.id) ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </span>
              </router-link>

              <ul
                v-if="subItem.submenu"
                class="submenu"
                :class="{ 'active': activeSubmenus.includes(subItem.id) }"
              >
                <li v-for="nestedItem in subItem.submenu" :key="nestedItem.id">
                  <router-link
                    :to="nestedItem.route"
                    @click="handleClick(nestedItem)"
                    :class="{ 'active': isActive(nestedItem.route?.name) }"
                  >
                    <span class="icon"><i :class="nestedItem.iconClass"></i></span>
                    <span class="title">{{ nestedItem.title }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  data() {
    return {
      isCollapsed: false,
      activeSubmenus: [],
      menuItems: [
        { id: 1, title: 'خانه', route: { name: 'Home' }, iconClass: 'fas fa-home' },
        {
          id: 2,
          title: 'خدمات',
          route: { name: 'Services' },
          iconClass: 'fas fa-cogs',
          submenu: [
            {
              id: 21,
              title: 'خدمات برخط',
              route: '',
              iconClass: 'fas fa-globe',
              submenu: [
                { id: 211, title: 'محاسبه مالیات اشخاص', route: { name: 'PersonTax' }, iconClass: 'fas fa-money-bill' },
                { id: 212, title: 'محاسبه استهلاک', route: { name: 'Depreciation' }, iconClass: 'fas fa-chart-line' },
                { id: 213, title: 'محاسبه حقوق و دستمزد', route: { name: 'Salary' }, iconClass: 'fas fa-wallet' },
                { id: 214, title: 'تولید کلید CSR', route: { name: 'CSRMaker' }, iconClass: 'fas fa-key' },
                { id: 215, title: 'جستجوی شناسه کالا/خدمات', route: { name: 'StuffIdSearch' }, iconClass: 'fas fa-search' }
              ]
            }
          ]
        },
        {
          id: 3,
          title: 'درباره ما',
          route: '',
          iconClass: 'fas fa-info-circle',
          submenu: [
            { id: 31, title: 'درباره شرکت بهین سیستم', route: { name: 'About' }, iconClass: 'fas fa-building' },
            { id: 32, title: 'معرفی اعضا', route: { name: 'Partners' }, iconClass: 'fas fa-users' },
            { id: 33, title: 'تماس', route: { name: 'Contact' }, iconClass: 'fas fa-phone' }
          ]
        },
        {
          id: 4,
          title: 'آموزش ',
          route: '',
          iconClass: 'fas fa-podcast',
          submenu: [
            { id: 41, title: 'آموزش های رایگان', route: { name: 'FreeLearning' }, iconClass: 'fas fa-book' }
          ]
        }
      ]
    };
  },
  methods: {
    toggleMenu() {
      this.isCollapsed = !this.isCollapsed;
      if (this.isCollapsed) {
        this.activeSubmenus = [];
      }
      this.$emit('toggle-sidebar', this.isCollapsed);
    },
    toggleSubmenu(id) {
      const index = this.activeSubmenus.indexOf(id);
      if (index === -1) {
        this.activeSubmenus.push(id);
      } else {
        this.activeSubmenus.splice(index, 1);
      }
    },
    handleClick(item) {
      console.log(`Navigated to ${item.title} at ${item.route.name}`);
    },
    isActive(routeName) {
      return this.$route.name === routeName;
    }
  }
};
</script>

<style scoped>
.sidebar {
  width: 250px;
  height: 100vh;
  background: linear-gradient(180deg, #2c3e50 0%, #34495e 100%);
  transition: width 0.3s ease;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  direction: rtl;
}

.sidebar.collapsed {
  width: 60px;
}

/* هدر */
.header {
  display: flex;
  align-items: center;
  padding: 15px;
  background: linear-gradient(135deg, #ffffff 0%, #ecf0f1 100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
}

.brand-name {
  color: #2c3e50;
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1;
  transition: opacity 0.3s;
}

.toggle-btn {
  width: 100%;
  padding: 12px;
  background-color: #3498db;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-btn:hover {
  background-color: #2980b9;
}

/* منو */
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu > ul > li > a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #ecf0f1;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu li a:hover {
  background-color: rgba(52, 73, 94, 0.8);
  padding-right: 25px;
}

.menu li a.active {
  background-color: #3498db;
  font-weight: bold;
  box-shadow: inset 4px 0 0 #2980b9;
}

.icon {
  width: 25px;
  text-align: center;
  order: -1;
  font-size: 18px;
}

.title {
  flex-grow: 1;
  margin-right: 15px;
  font-size: 16px;
}

.arrow {
  font-size: 12px;
  margin-right: 10px;
  transition: transform 0.3s;
}

.has-submenu .arrow i {
  transform: rotate(0deg);
}

.has-submenu .arrow i.fa-chevron-up {
  transform: rotate(180deg);
}

/* زیرمنو */
.submenu {
  background-color: #34495e;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

.submenu.active {
  max-height: 600px; /* تنظیم شده برای محتوای بیشتر */
}

.submenu li a {
  padding: 12px 20px 12px 40px;
  display: flex;
  align-items: center;
  color: #bdc3c7;
  transition: all 0.3s ease;
}

.submenu li a:hover {
  background-color: rgba(52, 73, 94, 0.8);
  padding-right: 45px;
}

.submenu li a.active {
  background-color: #2980b9;
  color: #ffffff;
}

.submenu .submenu li a {
  padding-right: 60px;
}
</style>