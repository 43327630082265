<template>
  <div id="app" dir="rtl">
    <!-- هدر ثابت -->
    <header class="topbar">
      <div class="topbar-content">
        <router-link to="/" class="logo-link">
          <img src="./assets/logo.png" alt="بهین سیستم ماندگار" class="logo" />
          <span class="brand-name">بهین سیستم ماندگار</span>
        </router-link>
        <nav class="topbar-nav">
          <router-link to="/" class="nav-link">خانه</router-link>
          <router-link :to="{ name: 'Services' }" class="nav-link">خدمات</router-link>
          <router-link :to="{ name: 'Contact' }" class="nav-link">تماس</router-link>
        </nav>
      </div>
    </header>

    <!-- منو و محتوا -->
    <div class="app-container">
      <Menu @toggle-sidebar="handleSidebarToggle" />
      <main class="main-content" :style="{ marginRight: sidebarMargin }">
        <router-view></router-view>
      </main>
    </div>

    <!-- فوتر -->
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-section">
          <h3 class="footer-title">بهین سیستم ماندگار</h3>
          <p>ارائه‌دهنده راهکارهای مالی و فناوری با بیش از دو دهه تجربه</p>
          <p>تاریخ و ساعت فعلی: {{ currentJalaliDateTime }}</p>
        </div>
        <div class="footer-section">
          <h3 class="footer-title">لینک‌های سریع</h3>
          <ul class="footer-links">
            <li><router-link to="/">خانه</router-link></li>
            <li><router-link to="/services">خدمات</router-link></li>
            <li><router-link to="/partners">اعضا</router-link></li>
            <li><router-link to="/contact">تماس با ما</router-link></li>
          </ul>
        </div>
        <div class="footer-section">
          <h3 class="footer-title">تماس با ما</h3>
          <p>تلفن: ۰۲۱-۱۲۳۴۵۶۷۸</p>
          <p>ایمیل: info@behinsystem.com</p>
          <div class="social-links">
            <a href="#" class="social-icon">📱</a>
            <a href="#" class="social-icon">✈️</a>
            <a href="#" class="social-icon">📧</a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; ۱۴۰۴ - تمامی حقوق محفوظ است</p>
      </div>
    </footer>
  </div>
</template>

<script>
import Menu from './views/common/sidebar.vue';
import moment from 'jalali-moment';

export default {
  name: 'App',
  components: {
    Menu
  },
  data() {
    return {
      currentJalaliDateTime: '',
      isSidebarCollapsed: false
    };
  },
  computed: {
    sidebarMargin() {
      return this.isSidebarCollapsed ? '60px' : '250px';
    }
  },
  methods: {
    updateDateTime() {
      this.currentJalaliDateTime = moment()
        .locale('fa')
        .format('YYYY/MM/DD - HH:mm:ss');
    },
    handleSidebarToggle(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    }
  },
  mounted() {
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000);
  }
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Vazir', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #e0e7ff 100%);
  direction: rtl;
}

/* هدر */
.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  z-index: 1000;
}

.topbar-content {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.brand-name {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
}

.topbar-nav {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #34495e;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-link:hover,
.nav-link.router-link-exact-active {
  color: #3498db;
}

/* محتوا */
.app-container {
  display: flex;
  margin-top: 70px; /* برای جبران فضای هدر */
}

.main-content {
  padding: 30px;
  flex-grow: 1;
  text-align: right;
  transition: margin-right 0.3s ease;
  /* margin-top: 70px; جبران ارتفاع هدر */
  animation: fadeIn 0.5s ease-in;
}

/* فوتر */
.footer {
  background-color: #2c3e50;
  color: white;
  padding: 40px 20px 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  text-align: right;
}

.footer-section {
  margin-bottom: 20px;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #3498db;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  color: #3498db;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.social-icon {
  color: #ecf0f1;
  font-size: 20px;
  text-decoration: none;
}

.social-icon:hover {
  color: #3498db;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #34495e;
  font-size: 12px;
  color: #bdc3c7;
}

/* انیمیشن */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
